/*
========================================================================||
Standard page
- templating so that we can create as many standard pages as we want
  via contentful CMS

Supported modules:
1) Hero
2) Wysiwyg
3) Document(s) module
    - title (single text)
    - description (single text)
    - documents (assets)
      - title
      - file > url

========================================================================||
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

// Component
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import Documents from '../components/documents';
import { richTextOptions } from '../components/helpers';

const ContentDiv = styled.div.attrs({
  className: 'space-y-6',
})`
  > * + h2 {
    margin-top: 30px !important;

    @media (min-width: 1024px) {
      margin-top: 72px !important;
    }
  }

  > * + h3 {
    @media (min-width: 1024px) {
      margin-top: 44px !important;
    }
  }

  > ul,
  > ol {
    margin-top: 8px !important;

    @media (min-width: 1024px) {
      margin-top: 18px !important;
    }
  }

  > div[data-gatsby-image-wrapper] {
    @media (min-width: 1024px) {
      margin-top: 32px !important;
    }
  }
`;

// Overwrite options
const options = {
  ...richTextOptions,
  renderNode: {
    ...richTextOptions.renderNode,
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="u-h4">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="u-body-lg font-normal">{children}</h3>
    ),
  },
};

const StandardTemplate = ({ data }) => {
  const params = data.allContentfulStandardPage.nodes[0];

  return (
    <Layout>
      <Seo
        title={params?.seoTitle || params.title}
        description={params?.seoDescription || ''}
      />
      <Hero
        title={params.heroTitle || params.title}
        description={params?.heroDescription?.heroDescription}
        ctas={params.heroCtas}
        image={params.heroImage}
        tagline={params.heroTagline}
      />
      <div className="py-16 lg:py-32 space-y-16 lg:space-y-32">
        {params.body && (
          <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
            <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
              <div className="col-span-12 lg:col-start-4 lg:col-span-6">
                <ContentDiv>{renderRichText(params.body, options)}</ContentDiv>
              </div>
            </div>
          </div>
        )}
        {(params.documentsTitle ||
          params.documentsDescription ||
          params.documents?.length) && (
          <Documents
            title={params.documentsTitle}
            description={params.documentsDescription}
            documents={params.documents}
          />
        )}
      </div>
    </Layout>
  );
};

StandardTemplate.defaultProps = {
  data: {},
};

StandardTemplate.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query ($slug: String!) {
    allContentfulStandardPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        seoTitle
        seoDescription
        heroTitle
        heroDescription {
          heroDescription
        }
        heroCtas {
          ...buttonFragment
        }
        heroImage {
          title
          description
          gatsbyImageData(formats: [WEBP, JPG], placeholder: BLURRED)
        }
        heroTagline
        body {
          raw
          references {
            ... on Node {
              ...homeNavFragment
              ...standardNavFragment
              ...builderNavFragment
              ...demoNavFragment
              ...contactNavFragment
              ...careersNavFragment
              ... on ContentfulAsset {
                contentful_id
                __typename
                title
                description
                gatsbyImageData(formats: [WEBP, JPG], placeholder: BLURRED)
              }
            }
          }
        }
        documentsTitle
        documentsDescription
        documents {
          title
          file {
            url
          }
        }
      }
    }
  }
`;

export default StandardTemplate;
