import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import Button from '../atoms/button';

// Components
import DownloadIcon from '../icons/icon-download.svg';

const Documents = ({ title, description, documents }) => (
  <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
    <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
      {(title || description) && (
        <div className="col-span-12 lg:col-start-4 lg:col-span-6">
          <div className="space-y-3 text-center">
            {title && <h2 className="u-h4">{title}</h2>}
            {description && <p>{description}</p>}
          </div>
        </div>
      )}
      {documents.length > 0 && (
        <div className="col-span-12 lg:col-start-3 lg:col-span-8 mt-8 lg:mt-12">
          {documents.map((doc, key) => (
            <div
              key={`document-${key}`}
              className={`flex justify-between items-center py-5 lg:py-7 ${
                key + 1 !== documents.length ? 'border-b border-grey-mid' : ''
              }`}
            >
              <p className="u-body-lg mr-12">{doc.title}</p>
              {doc?.file?.url && (
                <Button
                  as="a"
                  variant="secondary"
                  href={doc.file.url}
                  label="Download"
                  aria-label={`Download ${doc.title}`}
                  newTab
                  isShrink
                  icon={
                    <DownloadIcon className="fill-current stroke-current w-8 h-8" />
                  }
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

Documents.defaultProps = {
  title: '',
  description: '',
  documents: [],
};

Documents.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
};

export default Documents;
